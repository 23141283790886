<template>
  <div class="mx-auto">
    <Loading :isLoading= "this.isLoading" />
    <Modal v-if="showModal" :heightContainer="'auto'" :widthContainer="'40%'">
      <template v-slot:header>
        <h3 class="text-2xl" style="font-weight:700;font-family:Lato;text-align:center;">La VideoGrabación ha terminado</h3>
        <h4 class="text-xl" style="font-weight:300;font-family:Lato;text-align:center;">Si ha cometido algún error al repetir el texto le recomendamos que vuelva a grabar el video.</h4>
        <h4 class="text-xl" style="font-weight:500;font-family:Lato;text-align:center;">¿Desea grabar de nuevo el video?</h4>
      </template>
      <template v-slot:body>
        <div>
          <div style="display:flex;justify-content:center;">
            <input type="button" value="Si" class="buttonWithoutBackground" @click="startRecording()">
            <input type="button" value="No. Continuar" class="buttonSave" @click="uploadVideo()" style="margin-left:10px;">
          </div>
        </div>
      </template>
    </Modal>
    <Modal v-if="showInstructions" :heightContainer="'auto'" :widthContainer="'40%'">
      <template v-slot:header>
        <h3 class="text-2xl" style="font-weight:700;font-family:Lato;text-align:center;">Instrucciones</h3>
        <h4 class="text-xl" style="font-weight:300;font-family:Lato;text-align:center;">Deberá leer detenidamente y en voz alta el texto que aparecerá después que dé click al botón <span style="font-weight:bold;">"Comenzar VideoGrabación"</span>. Si ha terminado de leer todo el texto y el video aún no se detiene, puede dar click en el botón <span style="font-weight:bold;">"Detener VideoGrabación"</span> para terminar con el video.</h4>
      </template>
      <template v-slot:body>
        <div>
          <div style="display:flex;justify-content:center;">
            <input type="button" value="Comenzar VideoGrabación" class="buttonSave" @click="startRecording()">
            <input type="button" value="Cancelar" class="buttonWithoutBackground" @click="showInstructions = false" style="margin-left:10px;">
          </div>
        </div>
      </template>
    </Modal>
    <ModalTransparent v-show="showModalTransparent">
      <template v-slot:header>
        <div style="margin-top:0px;text-align:center;">
          <div style="display:flex;justify-content:center;margin-top:50px;">
            <div style="text-align:center;">
              <button v-if="recording" class="Rec buttonRecording">Recording</button>
            </div>
            <div>
              <input type="button" id="Button_StopVideo" v-if="recording" value="Detener VideoGrabación" class="buttonStopVideo" @click="stopRecording()">
            </div>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div class="marca" style="color:#000;">
            <section class="intro texto_intro" style="text-align:center;">
            </section>
            <section class="parrafos">
              <div class="historia texto_historia">
                <p>
                  {{text}}
                </p>
              </div>
            </section>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <input type="button" value="Cancelar" id="Button_Cancel" class="buttonWithoutBackground" @click="closeModalCommentHistory()">
          <input type="button" value="Añadir" id="Button_Save" class="buttonSave" @click="saveCommentHistory(title_history, comment_history)" style="margin-left:10px;">
        </div>
      </template>
    </ModalTransparent>
    <div style="justify-content:center;padding:20px;text-align: center;">
        <div v-if="showDescription" style="">
          <p class="title">
            Autenticación por VideoGrabación
          </p>
          <div class="flex" style="margin-top:0px;">
            <div class="w-full">
              <h4 class="steps">Paso 9 de 10</h4>
            </div>
          </div>
          <div style="margin-top:30px;">
            <p class="label-description" style="font-family:Roboto;font-weight:300;color:#000;text-align:center;">
              ¡Felicidades! está a punto de concluir el proceso, agradecemos su tiempo, como paso final de la captura de datos, 
              es necesario que inicie la grabación donde manifiesta su deseo por voluntad propia para formar parte de esta cooperativa, 
              así mismo nos indique que autoriza un descuento vía {{ discountMethod }} con una frecuencia de pago {{paymentFrecuency}}. Favor de iniciar la grabación para dar por terminado el proceso de captura de sus datos.
            </p>
          </div>
          <div style="display:flex;justify-content:center;margin-top:40px;">
            <img src="@/assets/video.svg" alt="" style="width:150px;">
          </div>
          <div style="margin-top:40px;">
            <p class="label-description" style="font-family:Roboto;font-weight:300;color:#000;text-align:center;">
              Favor de iniciar la grabación para dar por terminado el proceso de captura de sus datos.
            </p>
          </div>
          <div style="display:flex;justify-content:center;margin-top:60px;">
            <button class="btn-lg next_button" style="height:40px;width:240px; background-color:#fdce00" 
              type="submit, text-decoration:none;border-color:#fdce00;background:#fdce00;" id="Button_ShowInstructionModal" v-on:click="showInstructionsModal()">
                Iniciar Autenticación
            </button>
          </div>
        </div>
        <div v-show="recording">
          <label for="dispositivosDeAudio" hidden>Micrófono:</label><br>
          <select name="dispositivosDeAudio" id="dispositivosDeAudio" hidden></select>
          <br><br>
          <label for="dispositivosDeVideo" hidden>Cámara:</label><br>
          <select name="dispositivosDeVideo" id="dispositivosDeVideo" hidden></select>
          <br><br>
          <div style="display:flex;justify-content:center;position:relative;" class="">
            <div class="grid grid-cols-1">
              <div>
                <video muted="muted" id="video" style="width:100%;">
                </video>
              </div>
            </div>
          </div>
          <br><br>
          <br>
          <div>
            <p class="duration">{{duration}}</p>
          </div>
          <!-- <video id="video2" width="200" height="200" controls /> -->
      </div>
    </div>
  </div>
</template>

<script>
import('@/assets/css/stylesheet.css')

import Video from '@/classes/VideoRecorder';
import Loading from "@/components/Loading/VueLoading.vue";
import Modal from '@/components/Modal/Modal.vue'
import ModalTransparent from '@/components/Modal/ModalTransparent.vue'
import { saveRequest, getAdmissionRequest } from '@/api/user'
import Swal from "sweetalert2";
import {  getTokenDecoden } from "@/helpers/tokenauth";

export default {
  data() {
    return {
      startTime: "",
      mediaRecorder:  "",
      idInterval: "",
      duration:"",
      dispositivosDeAudio: "",
      dispositivosDeVideo: "",
      video: "",
      videoClass: "",
      personId: "",
      user_id: "",
      existMicrophone: false,
      existCamera: false,
      text: "",
      admission_id: "",
      client_classification: "",
      recordingLength: 0,
      showDescription: true,
      showModal: false,
      showModalTransparent: false,
      recording: false,
      isLoading: false,
      role: "",
      bussines_name: "FONCABSA",
      nameVideo: "video",
      admission_status: "",
      json: "",
      discountMethod: "",
      paymentFrecuency: "QUINCENAL",
      fullName: "",
      showInstructions: false,
    }
  },
  components: {
    Loading,
    Modal,
    ModalTransparent
  },
  async mounted() {
    await this.loadInfo();
    await this.validateAcceptanceRecording();
    this.setControlsVideo();
    
    this.videoClass = new Video();

    await this.existAutenticationVideoRecorder().then((response) => {
      if (!response) {
        try {
          this.videoClass.getRecorderScript(sessionStorage.getItem("login"), this.getProcessNameFromURL(), "FONCABSA", "activo", this.role).then(recorderScript => {
            this.text = this.getProcessedScript(recorderScript);
            console.log("texto: ",this.text);
            this.recordingLength = this.videoClass.getRecordingLength;
            if (typeof MediaRecorder === "undefined" || !this.webBrowserSupportsUserMedia()) {
              Swal.fire({
                title: "Aviso",
                text: "Es necesario cambiar a navegador Chrome o Safari para poder continuar con el proceso.",
                icon: "warning",
                confirmButtonColor: '#FEB72B',
              }).then(function() {
                // window.location.href = "/cuenta/principal";
                this.$router.push({path:"/cuenta/principal"});
              });
            }
            this.getDevices().then((devices)=> {
              this.validateDevices(devices);
            });
          }).catch(error => {
            Swal.fire({
              title: "Aviso",
              text: error,
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            }).then(function() {
              // window.location.href = "/cuenta/principal";
              this.$router.push({path:"/cuenta/principal"});
            });
          });
        } catch (error) {
          Swal.fire({
            title: "Aviso",
            text: error,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          }).then(function() {
            // window.location.href = "/cuenta/principal";
            this.$router.push({path:"/cuenta/principal"});
          });
        }
      }
    }).catch((response) => {
      
    });
  },
  methods: {
    getProcessedScript(script) {
      let wordsToBeReplaced = [
        { value: '"Diga su nombre completo"', newValue: this.fullName },
        { value: '“Diga el tipo de descuento que eligió ya sea NÓMINA o DOMICILIACIÓN”', newValue: this.discountMethod },
        { value: '“Diga la frecuencua elegida, ejemplo: QUINCENAL, MENSUAL”', newValue: this.paymentFrecuency }
      ]

      return this.videoClass.getProcessedScript(wordsToBeReplaced, script);
    },
    setControlsVideo() {
      var w = window.innerWidth;
      var h = window.innerHeight;

      var intro = document.getElementsByClassName("intro")[0];
      var historia = document.getElementsByClassName("historia")[0];
      var parrafos = document.getElementsByClassName("parrafos")[0];
      console.log("ancho de pantalla",);
      intro.style.fontSize = w / 30 + "px";
      if (w <= 1024) {
        historia.style.fontSize = w / 11 + "px";
      } else {
        historia.style.fontSize = w / 20 + "px";
      }
      parrafos.style.height = h + "px";

      $('#recButton').addClass("notRec");

      $('#recButton').click(function(){
        if($('#recButton').hasClass('notRec')){
          $('#recButton').removeClass("notRec");
          $('#recButton').addClass("Rec");
        }
        else{
          $('#recButton').removeClass("Rec");
          $('#recButton').addClass("notRec");
        }
      });	
    },
    animateScript() {
      var intro = document.getElementsByClassName("intro")[0];
      var historia = document.getElementsByClassName("historia")[0];
      intro.className = 'intro texto_intro animación_intro';
      historia.className = 'historia texto_historia animación_historia';
    },
    quitAnimateScript() {
      var intro = document.getElementsByClassName("intro")[0];
      var historia = document.getElementsByClassName("historia")[0];
      intro.className = 'intro texto_intro';
      historia.className = 'historia texto_historia';
    },
    getProcessNameFromURL() {
      return this.$route.params.process_name;
    },
    async existAutenticationVideoRecorder() {
      return await this.videoClass.getVideo(sessionStorage.getItem("login"), this.admission_id).then((response)=>{
        
        if (response.data.response.message == "Videos descargados exitosamente"){
          let swal = Swal;
          swal.fire({
            title: "Aviso",
            text: "La autenticación por VideoGrabación ya fue realizada",
            confirmButtonColor: '#FEB72B',
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            icon: "warning",
            confirmButtonColor: '#FEB72B'
          }).then((response)=>{
            
            console.log("status:", this.admission_status);
            if (this.admissionStatusIsValidToSave(this.admission_status)){
              this.saveRequest(true);
            }else{
              // window.location.href = "/terminos-y-condiciones";
              this.$router.push({path:"/terminos-y-condiciones"});
            }
          });

          return true;
        }else{
          return false
        }

      }).catch((error) => {
        return false;
      });
    },
    async saveRequest(redirect = true){

      var infoJson = this.buildJson();
      
      await saveRequest(infoJson).then((response ) => {
        this.isLoading = false;
        this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
      }).catch((error) => {
        this.isLoading = false;
      }).finally( () => {
        if(redirect){
          // window.location.href = "/terminos-y-condiciones";
            this.$router.push({path:"/terminos-y-condiciones"});
        }
      });
    },
    async loadInfo() {
      var auth = getTokenDecoden();
      this.personId = auth.obj.person['id'];
      this.user_id = auth["id"];
      this.role = auth.obj["role"];
      this.fullName = `${auth.obj.person["name"]} ${auth.obj.person["middle_name"]} ${auth.obj.person["paternal_name"]} ${auth.obj.person["maternal_name"]}`;
      this.searchInfo();
    },
    async searchInfo() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);

      if (json != null) {
        this.admission_status = json.data.response.admission_request.status;
        this.admission_id = json.data.response.admission_request.id;
        this.client_classification = json.data.response.admission_request.admission_information.client_classification;
        this.discountMethod = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method;
        this.paymentFrecuency = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency.toUpperCase()
      } else {
        await this.getAdmissionRequest();
      }
    },
    refresh() {
      let seconds = this.videoClass.getSecondsVideoRecording();
      if (seconds >= this.recordingLength) {
        this.stopRecording();
        return;
      }
      this.duration = this.videoClass.getRecordingTime(seconds);
    },
    setPersonalInfoToLocalStorage(user_id, jsonPersonalInfo) {
      sessionStorage.setItem(user_id, JSON.stringify(jsonPersonalInfo));
    },
    getJsonFromLocalStorageBy(user_id) {
      return JSON.parse(window.sessionStorage.getItem(user_id));
    },
    startRecording() {
      this.showModal = false;
      this.showInstructions = false
      if (!this.existMicrophone) {
        Swal.fire({
          title: "Aviso",
          text: "No se dectectó dispositivo de audio. Para continuar con el proceso favor de conectar un dispositivo de audio",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        })
        return;
      }

      if (!this.existCamera) {
        Swal.fire({
          title: "Aviso",
          text: "No se dectectó dispositivo de video. Para continuar con el proceso favor de conectar un dispositivo de video",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        })
        return;
      }

      this.videoClass.startRecording().then((isRecording) => {
        if (isRecording) {
          this.showDescription = false;
          this.showModalTransparent = true;
          this.animateScript();
          this.startCounting();
          this.recording = true;
          this.setScrollDown(); 
        } else {
          Swal.fire({
            title: "Aviso",
            text: "Favor de dar permisos para que el el sitio web pueda hacer uso de su Cámara y Microfono.",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
        }
      });
    },
    setScrollDown(){
      $('html, body').animate({scrollTop: $(document).height()}, 'slow');
    },
    uploadVideo() {
      this.showModal = false;
      this.isLoading = true;

      this.videoClass.uploadVideo(sessionStorage.getItem("login"), this.nameVideo, this.getProcessNameFromURL()).then((response)=>{
        let swal = Swal;
        var infoJson = this.buildJson();

        saveRequest(infoJson).then((response) => {
          this.isLoading=false;
          this.setPersonalInfoToLocalStorage(`admissionUser${this.user_id}`, response);
          swal.fire({
            title: "Aviso",
            text: "La VideoGrabación se guardó exitosamente",
            confirmButtonColor: '#FEB72B',
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
            icon: "success",
          }).then((response)=>{
            // window.location.href = "/terminos-y-condiciones";
            this.$router.push({path:"/terminos-y-condiciones"});
          });
        })
        .catch((error) => {
          this.isLoading=false;
        }).finally( () => {
        })
      }).catch((error)=>{
        this.isLoading = false;
        Swal.fire({
          title: "Aviso",
          text: "Ocurrió un error al guardar la VideoGrabación",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        })
      });
    },
    buildJson() {
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      
      return {
        token_auth: sessionStorage.getItem("login"),
        admission_request_id: this.admission_id,
        user_id: this.user_id,
        change_for: "video_complete",
        admission_request_params: {
          admission_information: json.data.response.admission_request.admission_information,
        },
      };
    },
    stopRecording() {
      this.videoClass.stopRecording();
      this.showModalTransparent = false;
      this.quitAnimateScript();
      this.stopCounting();
      this.showModal = true;
      this.recording = false;
    },
    startCounting() {
      // this.startTime = Date.now();
      this.videoClass.setStartTime = Date.now();

      this.duration = "";
      this.setTimeRecordingInterval();
    },
    setTimeRecordingInterval(){
      this.idInterval = setInterval(this.refresh, 500);
    },
    stopCounting() {
      clearInterval(this.idInterval);
    },
    getDevices() {
      return navigator
        .mediaDevices
        .enumerateDevices()
        .then(devices => {
            return devices
        }).catch((error) => {
          Swal.fire({
            title: "Aviso",
            text: "No se detectaron dispositivos de audio y video, favor de conectar los dispositivos",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
        });
    },
    validateDevices(devices){
      if (devices.length > 0) {
        devices.forEach((dispositivo) => {
          if (dispositivo.kind === "audioinput") {
            this.existMicrophone = true;
          } else if (dispositivo.kind === "videoinput") {
            this.existCamera = true;
          }
        })

        if (!this.existMicrophone) {
          Swal.fire({
            title: "Aviso",
            text: "No se detectó dispositivo de audio. Favor de conectar un dispositivo de audio",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
        }

        if (!this.existCamera) {
          Swal.fire({
            title: "Aviso",
            text: "No se detectó dispositivo de video. Favor de conectar un dispositivo de video",
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
        }
      } else {
        Swal.fire({
          title: "Aviso",
          text: "No se detectaron dispositivos de audio y video. Favor de conectar los dispositivos",
          icon: "warning",
          confirmButtonColor: '#FEB72B',
        }) 
      }
    },
    webBrowserSupportsUserMedia() {
      return !!(navigator.mediaDevices.getUserMedia);
    },
    async getAdmissionRequest() {
      await getAdmissionRequest(
          sessionStorage.getItem("login"),
          this.personId
      )
      .then((response) => {
        if (response.data.response.admission_request != null) {
          this.admission_id = response.data.response.admission_request.id; //se obtiene el id de la solicitud
          this.client_classification = response.data.response.admission_request.admission_information.client_classification;
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Aviso",
          text: "Se presentó un error al consultar las solicitudes",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      });
    },
    showInstructionsModal() {
      this.showInstructions = true;
    },
    async validateAcceptanceRecording(){
      let json = this.getJsonFromLocalStorageBy(`admissionUser${this.user_id}`);
      var payment_frecuency = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_frecuency.toUpperCase()
      var payment_method = json.data.response.admission_request.admission_information.saving_registration_attributes.payment_method.toUpperCase()
      var html = "<p style='font-size:14px'>Como paso final, en la grabación deberá manifestar su deseo por voluntad propio de formar parte del sistema financiero colaborativo FONCABSA,  indicando su autorización de descuento por "+payment_method+" y la frecuencia de pago "+payment_frecuency+". Concluida la video grabación se dará por concluido el proceso de registro.<br><br>Agradecemos su tiempo.</p>"

      Swal.fire({
        html: `<p style='font-size:14px'>Le informamos que por disposición oficial su voz e imagen  deben ser grabadas. <br><br>¿Autoriza que FONCABSA grave su voz e imagen?.</p>`,
        showDenyButton: true,
        showCancelButton: false,
        icon: 'info',
        confirmButtonText: `Autorizo`,
        denyButtonText: `No autorizo`,
        confirmButtonColor: '#FEB72B',
        denyButtonColor: '#FEB72B',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Aviso",
            html: html,
            icon: "warning",
            confirmButtonColor: '#FEB72B',
          })
        } else if (result.isDenied) {
          Swal.fire({
            html: `<p style='font-size:14px'>Para continuar con el trámite es necesario nos autorice grave su voz e imagen <br><br>¿Autoriza que FONCABSA grave su voz e imagen?.</p>`,
            showDenyButton: true,
            showCancelButton: false,
            icon: 'info',
            confirmButtonText: `Autorizo`,
            denyButtonText: `No autorizo`,
            confirmButtonColor: '#FEB72B',
            denyButtonColor: '#FEB72B',
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
              title: "Aviso",
              html: html,
              icon: "warning",
              confirmButtonColor: '#FEB72B',
            })
            } else if (result.isDenied) {
              // window.location.href = "/cuenta/principal";
              this.$router.push({path:"/cuenta/principal"});
            }
          })
        }
      })
    },
    admissionStatusIsValidToSave( status ) {
      const validStatuses = [
        "asignada",
        "en_revision",
        "rechazada",
        "cancelada"
      ];

      return (validStatuses.find(value => value == status))? false: true;
    }
  }
}
</script>

<style>
#video {
  border-radius:5px;
}
.button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
@media (max-width: 500px) {
  .button_popups {
    height: 50px !important;
  }
}

.buttonSave {
    border-color: 1px solid #FFCC00;
    background-color: #FFCC00; /* Green */
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-family:Roboto;
    widows: 10px;
    height: 30px;
    cursor: pointer;
}
.buttonSave:hover {
  background-color: #FFDB4B;
}

.buttonWithoutBackground {
    background-color: #fff; /* Green */
    border: 1px solid #FFCC00;
    border-radius: 5px;
    color: #FFCC00;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-family:Roboto;
    widows: 10px;
    height: 30px;
    cursor: pointer;
}
/* -------- */



.buttonStopVideo {
  background-color: red; /* Green */
  opacity: 1;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family:Lato;
  widows: 10px;
  height: 30px;
  cursor: pointer;
}


.duration {
  font-size:15px;
}
.video { position: relative; overflow: hidden; }
.marca { 
  position: relative;
  bottom: 0px;
  font-family: Arial;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  height: 100%;
  margin:0 auto;
  text-align: center;
  color: #000 !important;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.intro {
  position: absolute;
  top: 40%;
  left: 20%;
  z-index: 1;
  opacity: 0;
}

.texto_intro {
  font-family: "News Cycle", sans-serif;
  color: #4bd5ee;
  font-weight: 400;
  letter-spacing: .1em;
}

.historia {
  position: absolute;
  top: 100%;
}

/* .parrafos {
  position: absolute;
  left: 15%;
  width: 100%;
  z-index: 1;
} */

.texto_historia {
  padding:12%;
  font-family: "News Cycle", sans-serif;
  font-weight: 700;
  color: #fff;
  text-align: center;
  letter-spacing: .1em;
  line-height: 1.1em;
}

.animación_intro {
  animation: parrafos 50s linear 0s;
}

.animación_historia {
  animation: parrafos 230s ease-out 0s;
}

@keyframes intro {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes parrafos {
  0% {
    top: 70%;
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    top: -1000%;
    opacity: 0;
  }
}

.buttonRecording {
	width: 30px;
	height: 30px;
	font-size: 0;
	background-color: red;
	border: 0;
	border-radius: 35px;
	margin-right: 18px;
	outline: none;
}

.notRec{
	background-color: darkred;
}

.Rec{
	animation-name: pulse;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}

@media screen and (max-width: 1024px) {
  .animación_historia {
    animation: parrafos 130s linear 0s !important;
  }
  @keyframes parrafos {
  0% {
    top: 85%;
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    top: -1000%;
    opacity: 0;
  }
}
}

@media screen and (max-width: 768px) {
  .animación_historia {
    animation: parrafos 175s linear 0s !important;
  }
}

@media screen and (max-width: 640px) {
  .animación_historia {
    animation: parrafos 165s linear 0s !important;
  }
}

@media screen and (max-width: 501px) {
  .animación_historia {
    animation: parrafos 165s linear 0s !important;
  }
} 
</style>
