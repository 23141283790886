<template>
  <transition name="modal">
    <div class="modal-mask grid grid-cols-1">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    heightContainer: {
      type: String,
      default: 'auto'
    },
    widthContainer: {
      type: String,
      default: 'auto'
    }
  },
  data(){
    return {
      message: ""
    }
  }
}
</script>

<style scoped>
.modal-mask {
  overflow: hidden !important;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-container {
  margin: 0px auto;
  border-radius: 10px;
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100% !important;
  overflow-y: hidden !important;
}
.modal-header {
  border-bottom: none !important;
  padding: 2px;
}
.modal-footer {
  border-top: none !important;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  padding: 2px;
  margin: 5px 0;
}

.modal-default-button {
  float: right;
  background-color: #4CAF50; /* Green */
  opacity: 0.7;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 1px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-family:Lato;
  widows: 10px;
  height: 30px;;
  cursor: pointer;
}

.buttonBorderGreen {
    background-color: #fff; /* Green */
    border: 1px solid #85c875;
    border-radius: 5px;
    color: #85c875;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family:Lato;
    widows: 10px;
    height: 30px;
    cursor: pointer;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media screen and (max-width: 900px) {
  .modal-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  .modal-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .modal-container {
    width: 100% !important;
  }
}
</style>